import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { removeNonDigits } from '../utils';
import Layout from '../components/Layout';
import StoreInformation from '../components/StoreInformation';

export default function Contact() {
  const [loading, setLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    const elements = e.currentTarget;

    // honeypot triggered
    if (elements.honey.value) {
      setLoading(false);
      return;
    }

    const newMessage = {
      name: elements.customer.value.trim(),
      email: elements.email.value.toLowerCase().trim(),
      phone: removeNonDigits(elements.phone.value),
      message: elements.message.value.trim(),
    };

    const response = await fetch('/.netlify/functions/send-message', {
      method: 'post',
      body: JSON.stringify(newMessage),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // server error
    if (!response.ok) {
      setServerError(true);
      setLoading(false);
      return;
    }

    //success
    navigate('/success');
  };

  return (
    <Layout
      title="Contact Us | Falls Monument | (920) 467-4939"
      description="Please let us know if you have any questions or if you would like to set up an appoinment."
      urlPath="contact"
    >
      <ContactStyles>
        <div className="container">
          <div className="section-1">
            <h2>Contact Us</h2>
            <p>
              How can we help you? Let us know if you have any questions or if
              you would like to schedule an appointment.
            </p>
          </div>
          <div className="section-2">
            <form method="post" onSubmit={handleSubmit}>
              <h4>
                <span>Please fill out this form</span>
              </h4>
              <div className="form-item">
                <label htmlFor="customer">Your name</label>
                <input id="customer" required />
              </div>
              <div className="grid-col-2">
                <div className="form-item">
                  <label htmlFor="email">Email address</label>
                  <input id="email" type="email" required />
                </div>
                <div className="form-item">
                  <label htmlFor="phone">Phone number</label>
                  <input id="phone" required />
                </div>
              </div>
              <div className="form-item">
                <label htmlFor="message">How can we help you?</label>
                <textarea id="message" required />
              </div>
              <div className="form-item sr-only">
                <label htmlFor="honey">Please leave this field blank</label>
                <input id="honey" tabIndex={-1} />
              </div>
              <button
                type="submit"
                disabled={loading}
                className="submit-button"
              >
                {loading ? <span className="spinner" /> : 'Send your message'}
              </button>
              {serverError ? (
                <div className="server-error">
                  <svg fill="currentColor" viewBox="0 0 20 20">
                    <path
                      fillRule="evenodd"
                      d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                  <p>Server Error. Please try sending again.</p>
                </div>
              ) : null}
            </form>
          </div>
          <div className="section-3">
            <StoreInformation />
          </div>
        </div>
      </ContactStyles>
    </Layout>
  );
}

const ContactStyles = styled.div`
  padding: 4rem 1.5rem 5rem;

  .container {
    margin: 0 auto;
    max-width: 1000px;
    width: 100%;
    display: grid;
    grid-template-areas: 'section-1 section-3' 'section-2 section-3';
    gap: 0 5rem;
  }

  .section-1 {
    grid-area: section-1;
  }
  .section-2 {
    grid-area: section-2;
  }

  .section-3 {
    margin: 4rem 0 0;
    grid-area: section-3;
  }

  h4 {
    display: none;
    position: relative;
    margin: 0 0 1rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #1f2937;

    span {
      padding: 0 1.25rem 0 0;
      position: relative;
      background-color: #f8fafc;
      z-index: 200;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0.5625rem;
      width: 100%;
      height: 1px;
      background-color: #d1d5db;
      z-index: 10;
    }
  }

  p {
    margin: 0;
    max-width: 30rem;
  }

  form {
    margin: 0;
    display: flex;
    flex-direction: column;
    max-width: 30rem;
    width: 100%;
  }

  .grid-col-2 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(11rem, 1fr));
    gap: 0 1rem;
  }

  .form-item {
    margin: 1.25rem 0 0;
    display: flex;
    flex-direction: column;
  }

  label {
    margin: 0 0 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #374151;
  }

  input,
  textarea {
    appearance: none;
    margin: 0;
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    color: #262626;
    line-height: 1.25;
    background-color: #fff;
    border: 1px solid #e4e4e7;
    border-radius: 0.1875rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    transition-property: background-color, border-color, color, fill, stroke,
      opacity, box-shadow, transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 0.15s;

    &::placeholder {
      color: #d4d4d4;
    }

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      border-color: #4338ca;
      box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, #4338ca 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }
  }

  textarea {
    resize: vertical;
    min-height: 8rem;
  }

  .submit-button {
    position: relative;
    margin: 1.25rem 0 0;
    padding: 0.875rem 1.875rem;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: #0e7490;
    border: 1px solid #164e63;
    font-size: 0.9375rem;
    font-weight: 600;
    color: #fff;
    letter-spacing: 0.0125em;
    text-decoration: none;
    border-radius: 0.3125rem;
    box-shadow: inset 0 1px 1px #0891b2;
    cursor: pointer;

    svg {
      margin: 0 0.625rem 0 0;
      height: 0.9375rem;
      width: 0.9375rem;
      color: #2496b5;
    }

    &:hover {
      background-color: #087796;
    }

    &:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
    }

    &:focus-visible {
      box-shadow: #f9fafb 0px 0px 0px 2px, #1d4ed8 0px 0px 0px 4px,
        rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    }
  }

  @keyframes spinner {
    to {
      transform: rotate(360deg);
    }
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border-top: 2px solid rgba(255, 255, 255, 0.6);
    border-right: 2px solid transparent;
    animation: spinner 0.6s linear infinite;
  }

  .server-error {
    margin: 24px 0 0;
    padding: 16px;
    display: flex;
    align-items: center;
    background-color: #fdf2f2;
    border-left: 4px solid #f98080;
    border-top: 1px solid #fee2e2;
    border-bottom: 1px solid #fee2e2;
    border-right: 1px solid #fee2e2;
    border-radius: 0.125rem;

    svg {
      margin-right: 10px;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      color: #f98080;
    }

    p,
    a {
      margin: 0;
      color: #c81e1e;
      font-size: 0.875rem;
      line-height: 20px;
    }

    a {
      text-decoration: underline;
      transition: color 150ms ease-in-out;
    }
  }

  @media (max-width: 767px) {
    .container {
      grid-template-areas: 'section-1' 'section-3' 'section-2';
    }

    .section-2 {
      margin: 4rem 0 0;
    }

    .section-3 {
      margin: 0;
    }

    h3,
    h4 {
      margin-bottom: 1.75rem;
    }

    h4 {
      display: block;
    }

    p {
      margin: 0 0 4rem;
    }

    form {
      max-width: 100%;
    }
  }

  @media (max-width: 500px) {
    input,
    textarea {
      font-size: 1rem;
    }
  }
`;
